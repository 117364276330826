import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Login from "./Login";
import PatientList from "./PatientList";
// import ReportList from './ReportList';
import TherapistList from "./TherapistList";
import UserList from "./UserList";
import Menu from "./Menu";
import PatientDetail from "./PatientDetail";
import TherapistPatientForm from "./TherapistPatientForm";
import TherapistPatientReport from "./TherapistPatientReport";
import TherapistDetail from "./TherapistDetail";
import UserDetail from "./UserDetail";
import AdminNotes from "./AdminNotes";
import DownloadReport from "./DownloadReport";
import Assessments from "./Assessments";
import "../service/interceptor";
import AssessmentDetail from "./AssessmentDetail";

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (!localStorage.getItem("token")) return <Login />;
    else
      return [
        <a
          href="#"
          onClick={(e) => {
            localStorage.clear();
            window.location.href = "/admin";
          }}
          className="logout"
        >
          Log out
        </a>,
        <Menu />,
        <Router>
          <div>
            <Route
              path="/admin/admin_notes/:patient_id"
              component={AdminNotes}
            />
            <Route
              path="/admin/patient_form/:patient_id"
              component={TherapistPatientForm}
            />
            <Route
              path="/admin/patient_report/:patient_id"
              component={TherapistPatientReport}
            />
            <Route
              path="/admin/download_report/:patient_id"
              component={DownloadReport}
            />
            <Route
              path="/admin/patient/:patient_id"
              component={PatientDetail}
            />
            <Route
              path="/admin/therapist/:therapist_id"
              component={TherapistDetail}
            />
            <Route path="/admin/users/add" component={UserDetail} />
            <Route path="/admin/user/:user_id" component={UserDetail} />
            <Route path="/admin" exact component={PatientList} />
            <Route path="/admin/therapists" exact component={TherapistList} />
            <Route path="/admin/users" exact component={UserList} />
            <Route
              path="/admin/reports"
              exact
              render={(routeProps) => (
                <PatientList {...routeProps} report_view={true} />
              )}
            />
            <Route path="/admin/assessments" exact component={Assessments} />
            <Route path="/admin/assessments/:id" exact component={AssessmentDetail} />
          </div>
        </Router>,
      ];
  }
}

export default Admin;
