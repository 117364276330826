import { useState } from "react";
import axios from "axios";

const useMembership = () => {
  const [submitted, setSubmitted] = useState(false);
  const [exist, setExist] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const target = e.target;
    setExist(false);
    setLoading(true);
    setError(false);

    axios
      .post(`${window.server_url}/quick-register`, {
        first_name: target.first_name.value,
        last_name: target.last_name.value,
        email: target.email.value,
        password: target.password.value,
        type: target.type.value,
      })
      .then(({ data }) => {
        setLoading(false);
        if (data.patient_id) {
          if (data.exists) {
            setExist(true);
          } else {
            target.reset();
            setSubmitted(true);
            window.location.href =
              "https://maternalwell.com/free-membership-confirmation/";
          }
        }
      })
      .catch(() => {
        setError(true);
        setLoading(false)
      });
  };

  return {
    error,
    submitted,
    loading,
    exist,
    onSubmit,
  };
};

export { useMembership };
