import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import { Editor } from '@tinymce/tinymce-react';
import { site } from '../config/config'

var writtenNumber = require('written-number');
var Articles = require('articles')

// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

String.prototype.toProperCase = function () {
    return this.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
};

class TherapistPatientReport extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	content: null,
      delete_confirm: false 
    }
    this.loginToken = this.loginToken.bind(this);
    this.savePatient = this.savePatient.bind(this);
    this.submit = this.submit.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.report = this.report.bind(this);
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
	savePatient(data) {
    let token = localStorage.getItem('token'),
        that = this;

   

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/patient/' + that.props.match.params.patient_id;
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  saveFields() {
    let token = localStorage.getItem('token'),
        that = this;

   
    let data = {
      report_date: document.getElementById('report_date').value,
      report_status: document.getElementById('report_status').value
    }

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin/reports';
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  joinString(string, particle) {
    return [string.slice(0, -1).join(', '), string.slice(-1)[0]].join(string.length < 2 ? '' : ' '+particle+' ')
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
          let undef = '<strong style="background-color: #faa;">UNDEFINED</strong>';
          let patient = parsePatient(res.data);
          let p = Object.assign({}, patient);
          let report_date = p.report_date;
          Object.keys(p).forEach(key => {
            if(key != 'report_date' && (typeof p[key] === 'undefined' || p[key] === null)) p[key] = undef;
            if(typeof p[key] === 'string' && p[key].trim() === '') p[key] = undef;
          });
          let denies = [];
          let reports = [];
          let history = '';
          let map = {
            'social_history_trauma': 'trauma',
            'social_history_physical': 'physical abuse',
            'social_history_sexual': 'sexual abuse',
            'social_history_substance': 'substance abuse',
            'social_history_emotional': 'emotional abuse',
            'social_history_eating': 'eating disorders'
          };
          Object.keys(map).forEach(key => {
            if(patient[key] === 'reports') reports.push(map[key])
            if(patient[key] === 'denies') denies.push(map[key])
          })
          if(denies.length) {
            history += 'The patient denies any past or present '+that.joinString(denies, 'or')+'. ';
          }
          if(reports.length) {
            history += 'The patient reports a history of '+that.joinString(reports, 'and')+'. ';
          }

          let currently_seeing_nutritionist = '';

          if(p.currently_seeing_nutritionist == 'Yes') {
            currently_seeing_nutritionist = `The patient is currently consulting with a nutritionist or dietician. `;
          } else {
            currently_seeing_nutritionist = `The patient is not currently consulting with a nutritionist or dietician. `;
          }
            let test_results_keys = [
              'phq_15_raw_score',
              'phq_15_score',
              'gad_7_raw_score',
              'gad_7_score',
              'phq_9_raw_score',
              'phq_9_score',
              'anxiety_attacks',
              'dass_21_d_raw_score',
              'dass_21_d_adjusted_score',
              'dass_21_d_score',
              'dass_21_a_raw_score',
              'dass_21_a_adjusted_score',
              'dass_21_a_score',
              'dass_21_s_raw_score',
              'dass_21_s_adjusted_score',
              'dass_21_s_score',
              'eat_26_raw_score',
              'eat_26_score',
            ]
            let missing_results = [];
            test_results_keys.forEach(key => {
              if(!p.test_results || typeof p.test_results[key] === 'undefined') {
                p.test_results[key] = undef;
                missing_results[key] = true;
              }
            })
        
          
          let type_of_eater_denies = [];
          let type_of_eater_reports = [];
          let type_of_eater = '';
          let types_of_eater = p.type_of_eater ? p.type_of_eater.split(',') : [];

          console.log(types_of_eater);
          types_of_eater.push('lover of food eater');
          console.log(types_of_eater);
          p.types_of_eater.forEach(function(item) {
            if(types_of_eater.indexOf(item) !== -1) {
              type_of_eater_reports.push(item);
            } else {
              type_of_eater_denies.push(item);
            } 
          })
          if(type_of_eater_denies.length) {
            type_of_eater += 'The patient denies being a '+that.joinString(type_of_eater_denies, 'and')+'. ';
          }
          if(type_of_eater_reports.length) {
            type_of_eater += 'The patient reports being a '+that.joinString(type_of_eater_reports, 'and')+'. ';
          }
          let psych_factors = 'see no';
          if(patient.dass_psych_factors === 'see') psych_factors = 'see';
          if(patient.phq_psych_factors === 'see') psych_factors = 'see';

          let procedure = p.is_revision === 'yes' ? p.procedure.replace('Surgery','Revision') : p.procedure;

          let tobacco_quit_efforts = p.tobacco_quit_efforts;

          let defaultReport = '';
        	that.setState({
            content: patient.report_content || defaultReport,
            patient: patient,
        		therapists: res.therapists,
            level: res.level,
            user_id: res.user_id
        	})
          return patient;
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .then((patient) => {
        if(patient.report_submitted) {
          return fetch(window.server_url + '/admin/patients/download_report/' + this.props.match.params.patient_id, {
            method: 'GET',
            headers: {
              //'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'Authorization': 'Token '+token,
              'Accept': 'any',
            },
          })
        } else {
          return;
        }
      })
      .then(function (response) {
        if(!response) return;
         response.headers.forEach(function(val, key) { console.log(key + ' -> ' + val); });

          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              that.setState({fileError:response.statusText });
              throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        if(!res) return;
        console.log(res);
        
        if(res.filename) {
          that.setState({downloadFile: res.filename, loading: false});
        }
        
      })
      .catch(error => console.log(error) );  
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  update(key, value) {
    let _patient = Object.assign({}, this.state.patient);
    if(key === 'therapist') {
      let parts = value.split('|');
      _patient['therapist_id'] = parts[0];
      _patient['therapist'] = parts[1];
    } else {
      _patient[key] = value;
    }
    this.setState({
      patient: _patient
    })
  }
  handleEditorChange(content, editor) {
    this.setState({ content });
  }
  submit() {
    this.savePatient({
      report_content: this.state.content,
      report_status: 'Complete',
      report_submitted: moment().format(),
      report_signed_by: this.state.user_id
    });
    
  }

  report(patient) {
    
    let report_statuses = patient.report_statuses.map(status => {
      return <option value={status} selected={status === patient.report_status}>{status}</option>
    });
    let report_fields;
    if(['super_admin', 'admin'].indexOf(this.state.level) > -1) {
      report_fields = (<table className="space">
        <tr>
          <th>Surgeon Name</th>
          <th>Report Status</th>
          <th>Report Date</th>
        </tr>
        <tr>
          <td>{patient.surgeon}</td>
          <td>
            <select id="report_status" onClick={e => {e.stopPropagation()}}>
              {report_statuses}
            </select>
          </td>
          <td>
            <Cleave 
              options={{
                date: true,
                datePattern: ['m', 'd', 'Y']
              }} 
              className="date-field" 
              id="report_date"
              placeholder="MM/DD/YYYY"
              value={moment(patient.report_date).format('MM/DD/YYYY')} 
              name="report_date" />
          </td>
        </tr>
        <tr>
          <td>
           <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.saveFields()}}>Save Info</a>
           </td>
         </tr>
      </table>);
    }
    if(['advantum'].indexOf(this.state.level) > -1) {
      return (
        <div className="page admin form detail report">
          <h1>Patient Report: {patient.first_name} {patient.last_name}</h1>
          {this.state.downloadFile ? <a className="btn left" id="download-file" href={this.state.downloadFile}>Download Report</a> : 'No report created yet'}
        </div>
      );

    }
    return (
        <div className="page admin form detail report">
          <h1>Patient Report: {patient.first_name} {patient.last_name}</h1>
          {report_fields}
          {patient.report_submitted ? '' : <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient({report_content: this.state.content})}}>Save Draft</a>}
          {this.state.level === 'therapist' || this.state.level === 'super_therapist' ? <a className="btn left" href={`/admin/patient/${patient._id}`}>Cancel</a> :  <a className="btn left" href={`/admin/reports`}>Back</a>}
          {patient.report_submitted ? <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient({report_submitted: null,report_status: '', report_signed_by: null}) }}>Revert to Draft</a> : ''}
          {patient.report_submitted ? '' : <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.submit()}}>Sign & Submit</a>}
          {this.state.downloadFile ? <a className="btn left" id="download-file" href={this.state.downloadFile}>Download Report</a> : ''}
          {this.state.delete_confirm ? <span class="left">Really delete this report and start over from system data? <a className="btn red" href="#" onClick={e => {e.preventDefault(); this.savePatient({report_submitted: null,report_status: '', report_content: '', report_signed_by: null})}}>Delete Report</a></span> : <a className="btn red left" href="#" onClick={e => {e.preventDefault(); this.setState({delete_confirm: true})}}>Delete Report</a>}
          

          <div class="detail-area">
          {patient.report_submitted ? 
            <div>
              <h3>Signed on {patient.report_submitted}</h3>
              <div dangerouslySetInnerHTML={{__html: this.state.content}} /> 
            </div> : 
            <Editor
            name="report_content"
            apiKey="olp5oyhdx4m11pbnqdgheb5f83wlf2fpytg7011avx5d86up"
            init={{ browser_spellcheck: true, plugins: 'table' }}
            value={this.state.content} onEditorChange={this.handleEditorChange}
            />}

          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		

      if(['therapist', 'super_therapist', 'super_admin', 'admin', 'advantum'].indexOf(this.state.level) > -1) return this.report(this.state.patient);
      else {
        window.location.href = window.location.href.replace('_form','')
      }
    }
  }
}

export default TherapistPatientReport;