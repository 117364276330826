import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const getAssessments = () => axios.get(`${SERVER_URL}/admin/assessments`);

const getAssessment = (id) =>
  axios.get(`${SERVER_URL}/admin/assessments/${id}`);

export { getAssessments, getAssessment };
