
export const site = {
	slug: "mw",
	title: "Maternal Well",
	no_appt_message: "We’re sorry but there are no available appointment times with a licensed provider in your state. We will email you when one opens up. Thank you for your interest and we will follow up with you shortly.",
	success_message: "You will receive a confirmation email with the appointment details within a few minutes.",
	domain: "https://maternalwell.com",
	show_required: true,
	allow_restart: true,
	timezone: 'America/New_York'
}