import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';
import { site } from '../config/config'
// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


class PatientDetail extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	filter: null,
    	patient: null,
      loading: false,
      hide_confirm: false,
      therapists: null,
      level: null
    }
    this.loginToken = this.loginToken.bind(this);
    this.filterResults = this.filterResults.bind(this);
    this.superAdmin = this.superAdmin.bind(this);
    this.savePatient = this.savePatient.bind(this);
    this.hidePatient = this.hidePatient.bind(this);
    // this.downloadData = this.downloadData.bind(this);
  }
  // downloadData(data) {
  //   return (
  //     <ExcelFile>
  //       <ExcelSheet data={data} name="Patient Data">
  //         <ExcelColumn label="Key" value="key"/>
  //         <ExcelColumn label="Name" value="name"/>
  //         <ExcelColumn label="Value" value="value"/>
  //       </ExcelSheet>
  //     </ExcelFile>
  //   )
  // }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
  filterResults(value) {
  	this.setState({filter: value});
  }
	savePatient() {
    let token = localStorage.getItem('token'),
        that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'PUT',
        body: JSON.stringify(this.state.patient),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin';
        // if(!res.data) {
        //   localStorage.removeItem('token');
        //   window.location.href = '/admin';
        //   return false;
        // } else {
        //   // that.setState({
        //   //   patient: res.data
        //   // })
        // }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  hidePatient() {
    let token = localStorage.getItem('token'),
        that = this;

    fetch(window.server_url + '/admin/patients/hide/' + this.props.match.params.patient_id, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
              //localStorage.removeItem('token');
              //window.location.href = '/admin';
              //throw Error(response.statusText);
              return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        window.location.href='/admin';
        // if(!res.data) {
        //   localStorage.removeItem('token');
        //   window.location.href = '/admin';
        //   return false;
        // } else {
        //   // that.setState({
        //   //   patient: res.data
        //   // })
        // }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
      this.setState({loading: true});
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token '+token,
          'Accept': 'application/json',
        },
      })
      .then(function (response) {
        console.log(response);
          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              //throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log('res');
        console.log(res);
        if(!res.data) {
        	localStorage.removeItem('token');
        	window.location.href = '/admin';
        	return false;
        } else {
        	that.setState({
            patient: res.data,
        		therapists: res.therapists,
            level: res.level
        	})
        }
        // else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  getField(form, field) {
    let match;

    if(!form) return 'None';
    
    match = form.find(e => (e && e.name === field));
    if(match) return match.value;
  }
  update(key, value) {
    let _patient = Object.assign({}, this.state.patient);
    if(key === 'therapist') {
      let parts = value.split('|');
      _patient['therapist_id'] = parts[0];
      _patient['therapist'] = parts[1];
    } else {
      _patient[key] = value;
    }
    this.setState({
      patient: _patient
    })
  }
  verification(patient) {
    let verification_statuses = patient.verification_statuses.map(status => {
      return <option value={status} selected={status === patient.verification_status}>{status}</option>
    });
    let networks = patient.networks.map(status => {
      return <option value={status} selected={status === patient.network}>{status}</option>
    });
    let oon_benefits = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.oon_benefits}>{status}</option>
    });
    let submit_claims = ['', 'Yes', 'No', 'Review'].map(status => {
      return <option value={status} selected={status === patient.submit_claim}>{status}</option>
    });
    let deductible_applies = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.deductible_applies}>{status}</option>
    });
    let deductibles = patient.deductibles.map(status => {
      return <option value={status} selected={status === patient.deductible}>{status}</option>
    });

    let table = patient.data.map(row => {
      return (
        <tr>
          <td>{row.key}</td>
          <td>{row.name}</td>
          <td>{row.value}</td>
        </tr>
      );
    });
    
    return (
        <div className="page admin form detail">
          <h1>Patient Detail: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href="/admin">Cancel</a>
          <div class="detail-area">
          <table className="fullwidth">
            <tbody>
              <tr>
                <td><h3>Verification</h3></td>
              </tr>
              <tr>
                <td><strong>Verification Status</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('verification_status', e.target.value)}}>
                    {verification_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Verification Date</strong></td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.verification_date} 
                    onBlur={e => this.update('verification_date', moment(e.target.value).hour(10).format())} />
                </td>
              </tr>
              <tr>
                <td><strong>Network</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('network', e.target.value)}}>
                    {networks}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible', e.target.value)}}>
                    {deductibles}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible Remaining</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('deductible_remaining', e.target.value)} defaultValue={patient.deductible_remaining} />
                </td>
              </tr> 
              <tr>
                <td><strong>Deductible Applies</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible_applies', e.target.value)}}>
                    {deductible_applies}
                  </select>
                </td>
              </tr>              

              <tr>
                <td><strong>OOP Max Remaining</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('oop_max', e.target.value)} defaultValue={patient.oop_max} />
                </td>
              </tr>              
              <tr>
                <td><strong>OON Benefits</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('oon_benefits', e.target.value)}}>
                    {oon_benefits}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Submit Claim</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('submit_claim', e.target.value)}}>
                    {submit_claims}
                  </select>
                </td>
              </tr>              

              <tr>
                <td><strong>Amount Due</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('copay', e.target.value)} defaultValue={patient.copay} />
                </td>
              </tr>              
              <tr>
                <td><strong>Verification Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('verification_notes', e.target.value)} defaultValue={patient.verification_notes} />
                </td>
              </tr>
              <tr>
                <td><strong>DOB</strong></td>
                <td colspan="2">
                  {patient.dob}
                </td>
              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td><strong>Provider</strong></td>
                <td colspan="2">
                  {patient.therapist}
                </td>
              </tr>
              <tr>
                <td><strong>Insurance Name</strong></td>
                <td colspan="2">
                  {patient.insurance}
                </td>
              </tr>
              <tr>
                <td><strong>Insurance ID #</strong></td>
                <td colspan="2">
                  {patient.insurance_number}
                </td>
              </tr>
              <tr>
                <td><strong>Provider Customer Service #</strong></td>
                <td colspan="2">
                  {patient.insurance_phone}
                </td>
              </tr>
              <tr>
                <td><strong>Insured Name</strong></td>
                <td colspan="2">
                  {patient.insured_name}
                </td>
              </tr>
              <tr>
                <td><strong>Insured DOB</strong></td>
                <td colspan="2">
                  {patient.insured_dob}
                </td>
              </tr>
              <tr>
                <td><strong>Secondary Insurance</strong></td>
                <td colspan="2">
                  {patient.secondary_insurance}
                </td>
              </tr>
              <tr>
                <td><strong>Address</strong></td>
                <td colspan="2">
                  {patient.address}<br/>
                  {patient.city}, {patient.state} {patient.zip}
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      );
  }
  therapist(patient) {
    let invoice_tos = patient.invoice_tos.map(status => {
      return <option value={status} selected={status === patient.invoice_to}>{status}</option>
    });
    let invoicing_statuses = patient.invoicing_statuses.map(status => {
      return <option value={status} selected={status === patient.invoicing_status}>{status}</option>
    });
    let verification_statuses = patient.verification_statuses.map(status => {
      return <option value={status} selected={status === patient.verification_status}>{status}</option>
    });
    let scheduling_statuses = patient.scheduling_statuses.map(status => {
      return <option value={status} selected={status === patient.scheduling_status}>{status}</option>
    });
    let report_statuses = patient.report_statuses_therapist.map(status => {
      return <option value={status} selected={status === patient.report_status}>{status}</option>
    });
    let report_status_field;
    //if(['Hold', 'Sent'].indexOf(patient.report_status) > -1) {
    report_status_field = patient.report_status;
    // } else {
    //   report_status_field = (
    //     <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('report_status', e.target.value)}}>
    //       {report_statuses}
    //     </select>
    //   )
    // }
    // let invoicing_statuses = patient.invoicing_statuses.map(status => {
    //   return <option value={status} selected={status === patient.invoicing_status}>{status}</option>
    // });

    let table = '';
    let csv_link = '';

    if(patient.data) {
      table = patient.data.map(row => {
        return (
          <tr>
            <td>{row.key}</td>
            <td>{row.name}</td>
            <td>{row.value}</td>
          </tr>
        );
      });
      csv_link = <CSVLink filename="patient-data" className="download-link" data={patient.data}>Download CSV</CSVLink>
    } 

    let results_table;
    let results = patient.test_results;

    console.log('test_results', patient.test_results);

    if(results) {
      results_table = [
        <tr>
          <td><strong>Test</strong></td>
          <td><strong>Raw Score</strong></td>
          <td><strong>Adjusted Score</strong></td>
          <td><strong>Result</strong></td>
        </tr>
      ]
    
      if(results.phq_15_score) {
        results_table.push(
          <tr>
            <td><strong>PHQ-15</strong></td>
            <td>{results.phq_15_raw_score}</td>
            <td></td>
            <td>{results.phq_15_score}</td>
          </tr>
        )
      }
      if(results.gad_7_score) {
        results_table.push(
          <tr>
            <td><strong>GAD-7</strong></td>
            <td>{results.gad_7_raw_score}</td>
            <td></td>
            <td>{results.gad_7_score}</td>
          </tr>
        )
      }
      
      if(results.phq_9_score) {
        results_table.push(
          <tr>
            <td><strong>PHQ-9</strong></td>
            <td>{results.phq_9_raw_score}</td>
            <td></td>
            <td>{results.phq_9_score}</td>
          </tr>
        )
        results_table.push(
          <tr><td></td></tr>
        )
      }
      if(results.whympi_interference_score) {
        results_table.push(
          <tr>
            <td><strong>WHYMPI</strong></td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Part I</strong></td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Interference</strong></td>
            <td>{results.whympi_interference_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Support</strong></td>
            <td>{results.whympi_support_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Pain Severity</strong></td>
            <td>{results.whympi_pain_severity_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Life Control</strong></td>
            <td>{results.whympi_life_control_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Affective Distress</strong></td>
            <td>{results.whympi_affective_distress_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Part II</strong></td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Negative Responses</strong></td>
            <td>{results.whympi_negative_responses_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Solicitous Responses</strong></td>
            <td>{results.whympi_solicitous_responses_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Distracting Responses</strong></td>
            <td>{results.whympi_distracting_responses_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Part III</strong></td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Household Chores</strong></td>
            <td>{results.whympi_household_chores_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Outdoor Work</strong></td>
            <td>{results.whympi_outdoor_work_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Activities Away from Home</strong></td>
            <td>{results.whympi_activities_away_from_home_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>Social Activities</strong></td>
            <td>{results.whympi_social_activities_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>General Activity</strong></td>
            <td>{results.whympi_general_activity_score}</td>
          </tr>
        )
        results_table.push(
          <tr><td></td></tr>
        )
      }
      if(typeof results.pain_catastrophizing_score !== 'undefined') {
        results_table.push(
          <tr>
            <td><strong>Pain Catastrophizing Scale</strong></td>
            <td>{results.pain_catastrophizing_score}</td>
          </tr>
        )
        results_table.push(
          <tr><td></td></tr>
        )
      }

      if(results.dass_21_d_score) {
        results_table.push(
          <tr>
            <td><strong>DASS-21</strong></td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>d</strong></td>
            <td>{results.dass_21_d_raw_score}</td>
            <td>{results.dass_21_d_adjusted_score}</td>
            <td>{results.dass_21_d_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>a</strong></td>
            <td>{results.dass_21_a_raw_score}</td>
            <td>{results.dass_21_a_adjusted_score}</td>
            <td>{results.dass_21_a_score}</td>
          </tr>
        )
        results_table.push(
          <tr>
            <td><strong>s</strong></td>
            <td>{results.dass_21_s_raw_score}</td>
            <td>{results.dass_21_s_adjusted_score}</td>
            <td>{results.dass_21_s_score}</td>
          </tr>
        )
      }
      if(results.eat_26_score) {
        results_table.push(
          <tr>
            <td><strong>EAT-26</strong></td>
            <td>{results.eat_26_raw_score}</td>
            <td></td>
            <td>{results.eat_26_score}</td>
          </tr>
        )
      }
    }
    let info_table = [
      <tr>
        <td>Occupation</td>
        <td>{patient.occupation}</td>
      </tr>,
      <tr>
        <td>Tobacco</td>
        <td>{patient.tobacco_use}</td>
      </tr>,
      <tr>
        <td>BMI</td>
        <td>{patient.bmi}</td>
      </tr>,
      <tr>
        <td>Mental Health Illness?</td>
        <td>{patient.mental_health_illness}</td>
      </tr>,
      <tr>
        <td>Other</td>
        <td>{patient.mental_health_illness_other}</td>
      </tr>,
      <tr>
        <td>Mental Health Medications?</td>
        <td>{patient.mental_health_medications}</td>
      </tr>,
      <tr>
        <td>Other</td>
        <td>{patient.mental_health_medications_other}</td>
      </tr>,
      <tr>
        <td>Other Weight Loss Surgeries?</td>
        <td>{patient.weight_loss_surgeries}</td>
      </tr>,
      <tr>
        <td>Other</td>
        <td>{patient.weight_loss_surgeries_other}</td>
      </tr>

    ];
    if(results && results.anxiety_attacks) {
      info_table.push(
        <tr>
          <td>Anxiety attacks?</td>
          <td>{results.anxiety_attacks}</td>
        </tr>
      )
    }
    info_table.push(
      <tr>
        <td>Binge Eating</td>
      </tr>
    )
    
    let binge_eating;

    if(patient.binge_eating) binge_eating = patient.binge_eating;

    if(binge_eating && typeof binge_eating === 'array' && typeof binge_eating.forEach === 'function') binge_eating.forEach((item, key) => {
      info_table.push(
        <tr>
          <td>{item.name}</td>
          <td>{item.value}</td>
        </tr>
      )
    });


    
    return (
        <div className="page admin form detail">
          <h1>Patient Detail: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href="/admin">Cancel</a>
          <a className="btn left" href={`/admin/patient_report/${this.props.match.params.patient_id}`}>Patient Report</a>
          <a className="btn left" href={`/admin/admin_notes/${this.props.match.params.patient_id}`}>Admin Notes</a>
          <div class="detail-area">
          <table className="fullwidth">
            <tbody>
              <tr>
                <th>
                  <strong>Therapist</strong>
                </th>
                <th>
                  <strong>Scheduled Date</strong>
                </th>
                <th>
                  <strong>Scheduled Time</strong>
                </th>
                <th>
                  <strong>Phone Number</strong>
                </th>
                <th>
                  <strong>State</strong>
                </th>
                <th>
                  <strong>DOB</strong>
                </th>
                <th>
                  <strong>Procedure</strong>
                </th>
                <th>
                  <strong>Invoicing Status</strong>
                </th>
                <th>
                  <strong>Invoice To</strong>
                </th>
                <th>
                  <strong>Interview Status</strong>
                </th>
                <th>
                  <strong>Report Status</strong>
                </th>
              </tr>
              <tr>
                <td>
                  {patient.therapist}
                </td>
                <td>
                  {patient.appointment_date_modified}
                </td>
                <td>
                  {patient.appointment_time}
                </td>
                <td>
                  <input type="text" onChange={e => this.update('phone', e.target.value)} defaultValue={patient.phone} />
                </td>
                <td>
                  <input type="text" onChange={e => this.update('state', e.target.value)} defaultValue={patient.state} />
                </td>
                <td>
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.dob} 
                    onBlur={e => this.update('dob', moment(e.target.value).hour(10).format())} />
                </td>
                <td>
                  <input type="text" onChange={e => this.update('procedure', e.target.value)} defaultValue={patient.procedure} />
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoicing_status', e.target.value)}}>
                    {invoicing_statuses}
                  </select>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoice_to', e.target.value)}}>
                    {invoice_tos}
                  </select>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('scheduling_status', e.target.value)}}>
                    {scheduling_statuses}
                  </select>
                </td>
                <td>
                  {report_status_field}<br/>
                  <input 
                  style={{display: 'inline', marginTop: '10px'}}
                  type="checkbox" 
                  onClick={e => {e.stopPropagation()}} onChange={e => {
                    this.update('not_recommended', e.target.checked)
                  }} 
                  defaultChecked={patient.not_recommended ? 'checked' : ''} />&nbsp;
                  <label style={{display: 'inline'}}>Not Recommended</label>

                </td>
              </tr>
              <tr>
                <td>
                  <strong>APB Notes</strong>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <textarea onChange={e => this.update('apb_notes', e.target.value)} defaultValue={patient.apb_notes} />
                </td>
              </tr>

              <tr>
              <td>
              <p></p>
              </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h3>Demographics</h3>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td colspan="2">
                  {patient.address}<br/>
                  {patient.city}, {patient.state} {patient.zip}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Surgeon</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('surgeon', e.target.value)} defaultValue={patient.surgeon} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Age</strong>
                </td>
                <td colspan="2">
                  {patient.age}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Gender</strong>
                </td>
                <td colspan="2">
                  {patient.gender}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Language</strong>
                </td>
                <td colspan="2">
                  {patient.patient_language}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Marital Status</strong>
                </td>
                <td colspan="2">
                  {patient.marital_status
                    // #TODO: add this in to parsePatient
                  }
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Occupation</strong>
                </td>
                <td colspan="2">
                  {patient.occupation}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Height</strong>
                </td>
                <td colspan="2">
                  {patient.height_feet}' {patient.height_inches}"
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Weight</strong>
                </td>
                <td colspan="2">
                  {patient.weight} lb.
                </td>
              </tr>
              <tr>
                <td>
                  <strong>BMI</strong>
                </td>
                <td colspan="3">
                  {patient.bmi}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Tobacco Use</strong>
                </td>
                <td colspan="3">
                  {patient.tobacco_use}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Informed Consent</strong>
                </td>
                <td colspan="3">
                  {patient.informed_consent}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Testing Raw Data</strong>
                </td>
                <td colspan="3">

                 
                  {csv_link}

                </td>
              </tr>
              <tr><td><p>&nbsp;</p></td></tr>
              <tr>
                <td><strong>Results</strong></td>
              </tr>
              <tr>
                <td colspan="3"><table>{info_table}</table></td>
                <td colspan="4"><table>{results_table}</table></td>
              </tr>
              <tr><td><p>&nbsp;</p></td></tr>
              <tr>
                <td colspan="10">
                  <table>
                    <tr>
                      <th>Key</th>
                      <th>Name</th>
                      <th>Value</th>
                    </tr>
                    {table}
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          </div>
        </div>
      );
  }
  advantum(patient) {

    let invoicing_statuses = patient.invoicing_statuses.map(status => {
      return <option value={status} selected={status === patient.invoicing_status}>{status}</option>
    });
    let invoice_tos = patient.invoice_tos.map(status => {
      return <option value={status} selected={status === patient.invoice_to}>{status}</option>
    });
    let verification_statuses = patient.verification_statuses.map(status => {
      return <option value={status} selected={status === patient.verification_status}>{status}</option>
    });
    let billing_statuses = patient.billing_statuses.map(status => {
      return <option value={status} selected={status === patient.billing_status}>{status}</option>
    });
    let scheduling_statuses = patient.scheduling_statuses.map(status => {
      return <option value={status} selected={status === patient.scheduling_status}>{status}</option>
    });
    let report_statuses = patient.report_statuses.map(status => {
      return <option value={status} selected={status === patient.report_status}>{status}</option>
    });
    let networks = patient.networks.map(status => {
      return <option value={status} selected={status === patient.network}>{status}</option>
    });
    let deductibles = patient.deductibles.map(status => {
      return <option value={status} selected={status === patient.deductible}>{status}</option>
    });
    let mental_healths = ['','Yes','No'].map(status => {
      return <option value={status} selected={status === patient.mental_health}>{status}</option>
    });
    let telehealths = ['','Yes','No'].map(status => {
      return <option value={status} selected={status === patient.telehealth}>{status}</option>
    });

    let oon_benefits = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.oon_benefits}>{status}</option>
    });
    let submit_claims = ['', 'Yes', 'No', 'Review'].map(status => {
      return <option value={status} selected={status === patient.submit_claim}>{status}</option>
    });
    let deductible_applies = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.deductible_applies}>{status}</option>
    });

    return (
        <div className="page admin form detail">
          <h1>Patient Detail: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href="/admin">Cancel</a>
          <a className="btn left" href={`/admin/admin_notes/${this.props.match.params.patient_id}`}>Admin Notes</a>
          <div class="detail-area">
          <table className="fullwidth">
            <tbody>
              <tr>
                <th>
                  <strong>Procedure</strong>
                </th>
                <th>
                  <strong>Registration Status</strong>
                </th>

                <th>
                  <strong>Invoicing Status</strong>
                </th>
                <th>
                  <strong>Invoice To</strong>
                </th>
                <th>
                  <strong>Amount Received</strong>
                </th>
                <th>
                  <strong>Report Status</strong>
                </th>
                <th>
                  <strong>Report Date</strong>
                </th>
                <th>
                  <strong>Patient Notes</strong>
                </th>
                <th>
                  <strong>APB Notes</strong>
                </th>
              </tr>
              <tr>
                <td>
                  <p>{patient.procedure}</p>
                </td>
                <td>
                  <p>{patient.status}</p>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoicing_status', e.target.value)}}>
                    {invoicing_statuses}
                  </select>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoice_to', e.target.value)}}>
                    {invoice_tos}
                  </select>
                </td>
                <td>
                  <input type="text" onChange={e => this.update('cash_payment', e.target.value)} defaultValue={patient.cash_payment} />
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('report_status', e.target.value)}}>
                    {report_statuses}
                  </select>
                </td>
                <td>
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.report_date} 
                    onBlur={e => this.update('report_date', moment(e.target.value).hour(10).format())} />
                </td>
                <td>
                  <p>{patient.patient_notes}</p>
                </td>
                <td>
                  <input type="text" onChange={e => this.update('apb_notes', e.target.value)} defaultValue={patient.apb_notes} />
                </td>
              </tr>
              <tr>
              <td>
              <p></p>
              </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h3>Demographics</h3>
                </td>
                <td colspan="3">
                  <h3>Appointment Details</h3>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Phone</strong>
                </td>
                <td colspan="2">
                  {patient.phone}
                </td>
                <td>
                  <strong>Date</strong>
                </td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.appointment_date_modified} 
                    onBlur={e => this.update('appointment_date_modified', moment(e.target.value).format('MM/DD/YYYY'))} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td colspan="2">
                  {patient.email}
                </td>
                <td>
                  <strong>Time</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('appointment_time', e.target.value)} defaultValue={patient.appointment_time} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td colspan="2">
                  {patient.address}<br/>
                  {patient.city}, {patient.state} {patient.zip}
                </td>
                <td>
                  <strong>Therapist</strong>
                </td>
                <td colspan="2">
                  <select onChange={e => {this.update('therapist', e.target.value)}} ref={this.therapistInput}>
                    {this.state.therapists ? this.state.therapists.map(therapist => {
                      return <option value={therapist.fullslate_id +'|'+ therapist.first_name + ' ' + therapist.last_name} selected={patient.therapist_id === therapist.fullslate_id}>{therapist.first_name} {therapist.last_name}</option>
                    }) : ''}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Surgeon</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('surgeon', e.target.value)} defaultValue={patient.surgeon} />
                </td>
                <td>
                  <strong>Status</strong>
                </td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('scheduling_status', e.target.value)}}>
                    {scheduling_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Age</strong>
                </td>
                <td colspan="2">
                  {patient.age}
                </td>
                <td>
                  <strong>Notes</strong>
                </td>
                <td colspan="2">
                  {patient.appointment_notes}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>DOB</strong>
                </td>
                <td colspan="2">
                  {patient.dob}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Gender</strong>
                </td>
                <td colspan="2">
                  {patient.gender}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Language</strong>
                </td>
                <td colspan="2">
                  {patient.patient_language}
                </td>
              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td colspan="3">
                  <h3>Insurance</h3>
                </td>
                <td colspan="3">
                  <h3>Clinic Details</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Primary Insurance</strong></td>
                <td colspan="2">{patient.insurance}</td>
                <td><strong>Practice Name</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('practice_name', e.target.value)} defaultValue={patient.practice_name} />
                </td>
              </tr>
              <tr>
                <td><strong>Insurance #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('insurance_number', e.target.value)} defaultValue={patient.insurance_number} />
                </td>
                <td><strong>Practice ID</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('practice_id', e.target.value)} defaultValue={patient.practice_id} />
                </td>
              </tr>
              <tr>
                <td><strong>Customer Service #</strong></td>
                <td colspan="2">{patient.insurance_phone}</td>
                <td><strong>Surgeon</strong></td>
                <td colspan="2">{patient.surgeon}</td>
              </tr>
              <tr>
                <td><strong>Notes</strong></td>
                <td colspan="2">{patient.insurance_notes}</td>
                <td><strong>Phone #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_phone', e.target.value)} defaultValue={patient.clinic_phone} />
                </td>

              </tr>
              <tr>
                <td colspan="3"></td>
                <td><strong>Fax #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_fax', e.target.value)} defaultValue={patient.clinic_fax} />
                </td>

              </tr>
              <tr>
                <td colspan="3"></td>
                <td><strong>Primary Contact</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_primary_contact', e.target.value)} defaultValue={patient.clinic_primary_contact} />
                </td>

              </tr>
              <tr>
                <td colspan="3"></td>
                <td><strong>Referral</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('referral', e.target.value)} defaultValue={patient.referral} />
                </td>

              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td><strong>Secondary Insurance</strong></td>
                <td colspan="2">{patient.secondary_insurance}</td>
              </tr>
              <tr>
                <td><strong>Secondary Insurance #</strong></td>
                <td colspan="2">{patient.secondary_insurance_number}</td>
              </tr>
              <tr>
                <td><strong>Customer Service #</strong></td>
                <td colspan="2">{patient.secondary_insurance_phone}</td>
              </tr>
              <tr>
                <td><strong>Notes</strong></td>
                <td colspan="2">{patient.secondary_insurance_notes}</td>
              </tr>       
              <tr><td><p></p></td></tr>
              <tr>
                <td colspan="3">
                  <h3>Billing</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Guarantor Payment</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('guarantor_payment', e.target.value)} defaultValue={patient.guarantor_payment} />
                </td>
              </tr>              
              <tr>
                <td><strong>Insurance Reimbursement</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('insurance_reimbursement', e.target.value)} defaultValue={patient.insurance_reimbursement} />
                </td>
              </tr>              
              <tr>
                <td><strong>Total</strong></td>
                <td colspan="2">{/* TODO */}</td>
              </tr>              
              <tr>
                <td><strong>Patient Remainder</strong></td>
                <td colspan="2">{/* TODO */}</td>
              </tr>              
              <tr>
                <td><strong>Biller Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('biller_notes', e.target.value)} defaultValue={patient.biller_notes} />
                </td>
              </tr>              
              <tr>
                <td><strong>APB Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('apb_billing_notes', e.target.value)} defaultValue={patient.apb_billing_notes} />
                </td>
              </tr>        
              <tr><td><p></p></td></tr>
              <tr>
                <td><h3>Verification</h3></td>
              </tr>
              <tr>
                <td><strong>Mental Health</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('mental_health', e.target.value)}}>
                    {mental_healths}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Telehealth</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('telehealth', e.target.value)}}>
                    {telehealths}
                  </select>
                </td>
              </tr>

              <tr>
                <td><strong>Verification Status</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('verification_status', e.target.value)}}>
                    {verification_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Verification Date</strong></td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.verification_date} 
                    onBlur={e => this.update('verification_date', moment(e.target.value).hour(10).format())} />
                </td>
              </tr>
              <tr>
                <td><strong>Network</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('network', e.target.value)}}>
                    {networks}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible', e.target.value)}}>
                    {deductibles}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible Remaining</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('deductible_remaining', e.target.value)} defaultValue={patient.deductible_remaining} />
                </td>
              </tr>  
              <tr>
                <td><strong>Deductible Applies</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible_applies', e.target.value)}}>
                    {deductible_applies}
                  </select>
                </td>
              </tr>              

              <tr>
                <td><strong>OON Benefits</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('oon_benefits', e.target.value)}}>
                    {oon_benefits}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Submit Claim</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('submit_claim', e.target.value)}}>
                    {submit_claims}
                  </select>
                </td>
              </tr>              
            
              <tr>
                <td><strong>OOP Max Remaining</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('oop_max', e.target.value)} defaultValue={patient.oop_max} />
                </td>
              </tr>              
              <tr>
                <td><strong>Amount Due</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('copay', e.target.value)} defaultValue={patient.copay} />
                </td>
              </tr>   
              <tr>
                <td><strong>Secondary Insurance Entered?</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('secondary_insurance_added', e.target.value)} defaultValue={patient.secondary_insurance_added} />
                </td>
              </tr>              
           
              <tr>
                <td><strong>Verification Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('verification_notes', e.target.value)} defaultValue={patient.verification_notes} />
                </td>
              </tr>              
            </tbody>
          </table>
          </div>
        </div>
      );
  
  }
  admin(patient) {

    let invoicing_statuses = patient.invoicing_statuses.map(status => {
      return <option value={status} selected={status === patient.invoicing_status}>{status}</option>
    });
    let invoice_tos = patient.invoice_tos.map(status => {
      return <option value={status} selected={status === patient.invoice_to}>{status}</option>
    });
    let verification_statuses = patient.verification_statuses.map(status => {
      return <option value={status} selected={status === patient.verification_status}>{status}</option>
    });
    let billing_statuses = patient.billing_statuses.map(status => {
      return <option value={status} selected={status === patient.billing_status}>{status}</option>
    });
    let scheduling_statuses = patient.scheduling_statuses.map(status => {
      return <option value={status} selected={status === patient.scheduling_status}>{status}</option>
    });
    let report_statuses = patient.report_statuses.map(status => {
      return <option value={status} selected={status === patient.report_status}>{status}</option>
    });
    let networks = patient.networks.map(status => {
      return <option value={status} selected={status === patient.network}>{status}</option>
    });
    let deductibles = patient.deductibles.map(status => {
      return <option value={status} selected={status === patient.deductible}>{status}</option>
    });
    let insurances = patient.insurances.map(status => {
      return <option value={status} selected={status === patient.insurance}>{status}</option>
    });
    let mental_healths = ['','Yes','No'].map(status => {
      return <option value={status} selected={status === patient.mental_health}>{status}</option>
    });
    let telehealths = ['','Yes','No'].map(status => {
      return <option value={status} selected={status === patient.telehealth}>{status}</option>
    });

    let oon_benefits = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.oon_benefits}>{status}</option>
    });
    let submit_claims = ['', 'Yes', 'No', 'Review'].map(status => {
      return <option value={status} selected={status === patient.submit_claim}>{status}</option>
    });
    let deductible_applies = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.deductible_applies}>{status}</option>
    });



    return (
        <div className="page admin form detail">
          <h1>Patient Detail: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href="/admin">Cancel</a>
          <a className="btn left" href={`/admin/admin_notes/${this.props.match.params.patient_id}`}>Admin Notes</a>
          <div class="detail-area">
          <table className="fullwidth">
            <tbody>
              <tr>
                <th>
                  <strong>Procedure</strong>
                </th>
                <th>
                  <strong>Registration Status</strong>
                </th>

                <th>
                  <strong>Invoicing Status</strong>
                </th>
                <th>
                  <strong>Invoice To</strong>
                </th>
                <th>
                  <strong>Amount Received</strong>
                </th>
                <th>
                  <strong>Report Status</strong>
                </th>
                <th>
                  <strong>Report Date</strong>
                </th>
                <th>
                  <strong>Patient Notes</strong>
                </th>
                <th>
                  <strong>APB Notes</strong>
                </th>
              </tr>
              <tr>
                <td>
                  <p>{patient.procedure}</p>
                </td>
                <td>
                  <p>{patient.status}</p>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoicing_status', e.target.value)}}>
                    {invoicing_statuses}
                  </select>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoice_to', e.target.value)}}>
                    {invoice_tos}
                  </select>
                </td>
                <td>
                  <input type="text" onChange={e => this.update('cash_payment', e.target.value)} defaultValue={patient.cash_payment} />
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('report_status', e.target.value)}}>
                    {report_statuses}
                  </select>
                  <input 
                  style={{display: 'inline', marginTop: '10px'}}
                  type="checkbox" 
                  onClick={e => {e.stopPropagation()}} onChange={e => {
                    this.update('not_recommended', e.target.checked)
                  }} 
                  defaultChecked={patient.not_recommended ? 'checked' : ''} />&nbsp;
                  <label style={{display: 'inline'}}>Not Recommended</label>
                </td>
                <td>
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.report_date} 
                    onBlur={e => this.update('report_date', moment(e.target.value).hour(10).format())} />
                </td>
                <td>
                  <p>{patient.patient_notes}</p>
                </td>
                <td>
                  <input type="text" onChange={e => this.update('apb_notes', e.target.value)} defaultValue={patient.apb_notes} />
                </td>
              </tr>
              <tr>
              <td>
              <p></p>
              </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h3>Demographics</h3>
                </td>
                <td colspan="3">
                  <h3>Appointment Details</h3>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Phone</strong>
                </td>
                <td colspan="2">
                  {patient.phone}
                </td>
                <td>
                  <strong>Date</strong>
                </td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.appointment_date_modified} 
                    onBlur={e => this.update('appointment_date_modified', moment(e.target.value).format('MM/DD/YYYY'))} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td colspan="2">
                  {patient.email}
                </td>
                <td>
                  <strong>Time</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('appointment_time', e.target.value)} defaultValue={patient.appointment_time} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td colspan="2">
                  {patient.address}<br/>
                  {patient.city}, {patient.state} {patient.zip}
                </td>
                <td>
                  <strong>Therapist</strong>
                </td>
                <td colspan="2">
                  <select onChange={e => {this.update('therapist', e.target.value)}} ref={this.therapistInput}>
                    {this.state.therapists ? this.state.therapists.map(therapist => {
                      return <option value={therapist.fullslate_id +'|'+ therapist.first_name + ' ' + therapist.last_name} selected={patient.therapist_id === therapist.fullslate_id}>{therapist.first_name} {therapist.last_name}</option>
                    }) : ''}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Surgeon</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('surgeon', e.target.value)} defaultValue={patient.surgeon} />
                </td>
                <td>
                  <strong>Status</strong>
                </td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('scheduling_status', e.target.value)}}>
                    {scheduling_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Age</strong>
                </td>
                <td colspan="2">
                  {patient.age}
                </td>
                <td>
                  <strong>Notes</strong>
                </td>
                <td colspan="2">
                  {patient.appointment_notes}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>DOB</strong>
                </td>
                <td colspan="2">
                  {patient.dob}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Gender</strong>
                </td>
                <td colspan="2">
                  {patient.gender}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Language</strong>
                </td>
                <td colspan="2">
                  {patient.patient_language}
                </td>
              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td colspan="3">
                  <h3>Insurance</h3>
                </td>
                <td colspan="3">
                  <h3>Clinic Details</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Primary Insurance</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('insurance', e.target.value)}}>
                    {insurances}
                  </select>
                </td>
                <td><strong>Practice Name</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('practice_name', e.target.value)} defaultValue={patient.practice_name} />
                </td>
              </tr>
              <tr>
                <td><strong>Insurance #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('insurance_number', e.target.value)} defaultValue={patient.insurance_number} />
                </td>
                <td><strong>Practice ID</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('practice_id', e.target.value)} defaultValue={patient.practice_id} />
                </td>

              </tr>
              <tr>
                <td><strong>Customer Service #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('insurance_phone', e.target.value)} defaultValue={patient.insurance_phone} />
                </td>
                <td><strong>Surgeon</strong></td>
                <td colspan="2">{patient.surgeon}</td>

              </tr>
              <tr>
                <td><strong>Notes</strong></td>
                <td colspan="2">{patient.insurance_notes}</td>
                <td><strong>Phone #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_phone', e.target.value)} defaultValue={patient.clinic_phone} />
                </td>

              </tr>
              <tr>
                <td colspan="3"></td>
                <td><strong>Fax #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_fax', e.target.value)} defaultValue={patient.clinic_fax} />
                </td>
              </tr>
              <tr>
                <td colspan="3"></td>
                <td><strong>Primary Contact</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_primary_contact', e.target.value)} defaultValue={patient.clinic_primary_contact} />
                </td>

              </tr>
              <tr>
                <td colspan="3"></td>
                <td><strong>Referral</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('referral', e.target.value)} defaultValue={patient.referral} />
                </td>

              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td><strong>Secondary Insurance</strong></td>
                <td colspan="2">{patient.secondary_insurance}</td>
              </tr>
              <tr>
                <td><strong>Secondary Insurance #</strong></td>
                <td colspan="2">{patient.secondary_insurance_number}</td>
              </tr>
              <tr>
                <td><strong>Customer Service #</strong></td>
                <td colspan="2">{patient.secondary_insurance_phone}</td>
              </tr>
              <tr>
                <td><strong>Notes</strong></td>
                <td colspan="2">{patient.secondary_insurance_notes}</td>
              </tr>              
              <tr><td><p></p></td></tr>
              <tr>
                <td><h3>Verification</h3></td>
              </tr>
              <tr>
                <td><strong>Mental Health</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('mental_health', e.target.value)}}>
                    {mental_healths}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Telehealth</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('telehealth', e.target.value)}}>
                    {telehealths}
                  </select>
                </td>
              </tr>

              <tr>
                <td><strong>Verification Status</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('verification_status', e.target.value)}}>
                    {verification_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Verification Date</strong></td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.verification_date} 
                    onBlur={e => this.update('verification_date', moment(e.target.value).hour(10).format())} />
                </td>
              </tr>
              <tr>
                <td><strong>Network</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('network', e.target.value)}}>
                    {networks}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible', e.target.value)}}>
                    {deductibles}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible Remaining</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('deductible_remaining', e.target.value)} defaultValue={patient.deductible_remaining} />
                </td>
              </tr> 
              <tr>
                <td><strong>Deductible Applies</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible_applies', e.target.value)}}>
                    {deductible_applies}
                  </select>
                </td>
              </tr>              

              <tr>
                <td><strong>OON Benefits</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('oon_benefits', e.target.value)}}>
                    {oon_benefits}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Submit Claim</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('submit_claim', e.target.value)}}>
                    {submit_claims}
                  </select>
                </td>
              </tr>              
    
              <tr>
                <td><strong>OOP Max Remaining</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('oop_max', e.target.value)} defaultValue={patient.oop_max} />
                </td>
              </tr>              
          
              <tr>
                <td><strong>Amount Due</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('copay', e.target.value)} defaultValue={patient.copay} />
                </td>
              </tr>    
              <tr>
                <td><strong>Invoice URL</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('invoice_url', e.target.value)} defaultValue={patient.invoice_url} />
                </td>
              </tr>                        
              <tr>
                <td><strong>Verification Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('verification_notes', e.target.value)} defaultValue={patient.verification_notes} />
                </td>
              </tr>              
            </tbody>
          </table>
          </div>
        </div>
      );
  }
  superAdmin(patient) {
    let registration_statuses = patient.registration_statuses.map(status => {
      return <option value={status} selected={status === patient.status}>{status}</option>
    });

    let invoicing_statuses = patient.invoicing_statuses.map(status => {
      return <option value={status} selected={status === patient.invoicing_status}>{status}</option>
    });
    let invoice_tos = patient.invoice_tos.map(status => {
      return <option value={status} selected={status === patient.invoice_to}>{status}</option>
    });
    let verification_statuses = patient.verification_statuses.map(status => {
      return <option value={status} selected={status === patient.verification_status}>{status}</option>
    });
    let billing_statuses = patient.billing_statuses.map(status => {
      return <option value={status} selected={status === patient.billing_status}>{status}</option>
    });
    let report_statuses = patient.report_statuses.map(status => {
      return <option value={status} selected={status === patient.report_status}>{status}</option>
    });
    let mental_healths = ['','Yes','No'].map(status => {
      return <option value={status} selected={status === patient.mental_health}>{status}</option>
    });
    let telehealths = ['','Yes','No'].map(status => {
      return <option value={status} selected={status === patient.telehealth}>{status}</option>
    });
    console.log(patient.scheduling_statuses);

    let scheduling_statuses = patient.scheduling_statuses.map(status => {
      return <option value={status} selected={status === patient.scheduling_status}>{status}</option>
    });

    let oon_benefits = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.oon_benefits}>{status}</option>
    });
    let submit_claims = ['', 'Yes', 'No', 'Review'].map(status => {
      return <option value={status} selected={status === patient.submit_claim}>{status}</option>
    });
    let deductible_applies = ['', 'Yes', 'No'].map(status => {
      return <option value={status} selected={status === patient.deductible_applies}>{status}</option>
    });

    let deductibles = patient.deductibles.map(status => {
      return <option value={status} selected={status === patient.deductible}>{status}</option>
    });


    let csv_link = '';

    if(patient.data) csv_link = <CSVLink filename="patient-data" className="download-link" data={patient.data}>Download CSV</CSVLink>
    

    return (
        <div className="page admin form detail">
          <h1>Patient Detail: {patient.first_name} {patient.last_name}</h1>
          <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.savePatient()}}>Save</a>
          <a className="btn left" href="/admin">Cancel</a>
          <a className="btn left" href={`/admin/admin_notes/${this.props.match.params.patient_id}`}>Admin Notes</a>
          {this.state.hide_confirm ? <span class="left">Really hide this patient forever? <a className="btn red" href="#" onClick={e => {e.preventDefault(); this.hidePatient()}}>Hide Patient</a></span> :
            <a className="btn left" href="#" onClick={e => {e.preventDefault(); this.setState({hide_confirm: true})}}>Hide Patient</a>}
          <div class="detail-area">
          <table className="fullwidth">
            <tbody>
              <tr>
                <th>
                  <strong>Procedure</strong>
                </th>
                <th>
                  <strong>Registration Status</strong>
                </th>
                <th>
                  <strong>Verification Status</strong>
                </th>
                <th>
                  <strong>Invoicing Status</strong>
                </th>
                <th>
                  <strong>Invoice To</strong>
                </th>
                <th>
                  <strong>Amount Received</strong>
                </th>
                <th>
                  <strong>Billing Status</strong>
                </th>
                <th>
                  <strong>Patient Notes</strong>
                </th>
                <th>
                  <strong>APB Notes</strong>
                </th>
              </tr>
              <tr>
                <td>
                  <p>{patient.procedure}</p>
                </td>
                <td>
                  {patient.status}
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('verification_status', e.target.value)}}>
                    {verification_statuses}
                  </select>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoicing_status', e.target.value)}}>
                    {invoicing_statuses}
                  </select>
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('invoice_to', e.target.value)}}>
                    {invoice_tos}
                  </select>
                </td>
                <td>
                  <input type="text" onChange={e => this.update('cash_payment', e.target.value)} defaultValue={patient.cash_payment} />
                </td>
                <td>
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('billing_status', e.target.value)}}>
                    {billing_statuses}
                  </select>
                </td>
                <td>
                  <p>{patient.patient_notes}</p>
                </td>
                <td>
                  <input type="text" onChange={e => this.update('apb_notes', e.target.value)} defaultValue={patient.apb_notes} />
                </td>
              </tr>
              <tr>
              <td>
              <p></p>
              </td>
              </tr>
              <tr>
                <td colspan="3">
                  <h3>Demographics</h3>
                </td>
                <td colspan="3">
                  <h3>Appointment Details</h3>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Phone</strong>
                </td>
                <td colspan="2">
                  {patient.phone}
                </td>
                <td>
                  <strong>Date</strong>
                </td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.appointment_date_modified} 
                    onBlur={e => this.update('appointment_date_modified', moment(e.target.value).format('MM/DD/YYYY'))} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Email</strong>
                </td>
                <td colspan="2">
                  {patient.email}
                </td>
                <td>
                  <strong>Time</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('appointment_time', e.target.value)} defaultValue={patient.appointment_time} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Address</strong>
                </td>
                <td colspan="2">
                  {patient.address}<br/>
                  {patient.city}, {patient.state} {patient.zip}
                </td>
                <td>
                  <strong>Therapist</strong>
                </td>
                <td colspan="2">
                  <select onChange={e => {this.update('therapist', e.target.value)}} ref={this.therapistInput}>
                    {this.state.therapists ? this.state.therapists.map(therapist => {
                      return <option value={therapist.fullslate_id +'|'+ therapist.first_name + ' ' + therapist.last_name} selected={patient.therapist_id === therapist.fullslate_id}>{therapist.first_name} {therapist.last_name}</option>
                    }) : ''}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Surgeon</strong>
                </td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('surgeon', e.target.value)} defaultValue={patient.surgeon} />
                </td>
                <td>
                  <strong>Status</strong>
                </td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('scheduling_status', e.target.value)}}>
                    {scheduling_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Age</strong>
                </td>
                <td colspan="2">
                  {patient.age}
                </td>
                <td>
                  <strong>Notes</strong>
                </td>
                <td colspan="2">
                  {patient.appointment_notes}
                </td>
              </tr>
              <tr>
                <td>
                  <strong>DOB</strong>
                </td>
                <td colspan="2">
                  {patient.dob}
                </td>
                <td>
                  <strong>Report Status</strong>
                </td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('report_status', e.target.value)}}>
                    {report_statuses}
                  </select>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Gender</strong>
                </td>
                <td colspan="2">
                  {patient.gender}
                </td>
                <td>
                  <strong>Report Date</strong>
                </td>
                <td colspan="2">
                  <Cleave 
                    options={{
                      date: true,
                      datePattern: ['m', 'd', 'Y']
                    }} 
                    placeholder="MM/DD/YYYY"
                    value={patient.report_date} 
                    onBlur={e => this.update('report_date', moment(e.target.value).hour(10).format())} />
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Language</strong>
                </td>
                <td colspan="2">
                  {patient.patient_language}
                </td>
                <td colspan="3">
                </td>

              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td colspan="3">
                  <h3>Insurance</h3>
                </td>
                <td colspan="3">
                  <h3>Clinic Details</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Primary Insurance</strong></td>
                <td colspan="2">{patient.insurance}</td>
                <td><strong>Practice Name</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('practice_name', e.target.value)} defaultValue={patient.practice_name} />
                </td>
              </tr>
              <tr>
                <td><strong>Insurance #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('insurance_number', e.target.value)} defaultValue={patient.insurance_number} />
                </td>
                <td><strong>Practice ID</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('practice_id', e.target.value)} defaultValue={patient.practice_id} />
                </td>
              </tr>
              <tr>
                <td><strong>Customer Service #</strong></td>
                <td colspan="2">{patient.insurance_phone}</td>
                <td><strong>Surgeon</strong></td>
                <td colspan="2">{patient.surgeon}</td>

              </tr>
              <tr>
                <td><strong>Notes</strong></td>
                <td colspan="2">{patient.insurance_notes}</td>
                <td><strong>Phone #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_phone', e.target.value)} defaultValue={patient.clinic_phone} />
                </td>

              </tr>
              <tr>
                <td><strong>Secondary Insurance</strong></td>
                <td colspan="2">{patient.secondary_insurance}</td>
                <td><strong>Fax #</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_fax', e.target.value)} defaultValue={patient.clinic_fax} />
                </td>

              </tr>
              <tr>
                <td><strong>Secondary Insurance #</strong></td>
                <td colspan="2">{patient.secondary_insurance_number}</td>
                <td><strong>Primary Contact</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('clinic_primary_contact', e.target.value)} defaultValue={patient.clinic_primary_contact} />
                </td>

              </tr>
              <tr>
                <td><strong>Secondary Insurance #</strong></td>
                <td colspan="2">{patient.secondary_insurance_number}</td>
                <td><strong>Referral</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('referral', e.target.value)} defaultValue={patient.referral} />
                </td>

              </tr>
              <tr><td><p></p></td></tr>
              <tr>
                <td colspan="3">
                  <h3>Billing</h3>
                </td>
              </tr>
              <tr>
                <td><strong>Guarantor Payment</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('guarantor_payment', e.target.value)} defaultValue={patient.guarantor_payment} />
                </td>
              </tr>              
              <tr>
                <td><strong>Insurance Reimbursement</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('insurance_reimbursement', e.target.value)} defaultValue={patient.insurance_reimbursement} />
                </td>
              </tr>              
              <tr>
                <td><strong>Total</strong></td>
                <td colspan="2">{/* TODO */}</td>
              </tr>              
              <tr>
                <td><strong>Patient Remainder</strong></td>
                <td colspan="2">{/* TODO */}</td>
              </tr>              
              <tr>
                <td><strong>Biller Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('biller_notes', e.target.value)} defaultValue={patient.biller_notes} />
                </td>
              </tr>              
              <tr>
                <td><strong>APB Notes</strong></td>
                <td colspan="2">
                  <textarea onChange={e => this.update('apb_billing_notes', e.target.value)} defaultValue={patient.apb_billing_notes} />
                </td>
              </tr> 
              <tr>
                <td><strong>Invoice URL</strong></td>
                <td colspan="2">
                  <input type="text" onChange={e => this.update('invoice_url', e.target.value)} defaultValue={patient.invoice_url} />
                </td>
              </tr> 
              <tr><td><p></p></td></tr>
              <tr>
                <td><h3>Verification</h3></td>
              </tr>
              <tr>
                <td><strong>Mental Health</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('mental_health', e.target.value)}}>
                    {mental_healths}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Telehealth</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('telehealth', e.target.value)}}>
                    {telehealths}
                  </select>
                </td>
              </tr>
              <tr>
                <td><strong>Verification Status</strong></td>
                <td colspan="2">
                  {patient.verification_status}
                </td>
              </tr>
              <tr>
                <td><strong>Verification Date</strong></td>
                <td colspan="2">
                  {patient.verification_date} 
                </td>
              </tr>
              <tr>
                <td><strong>Network</strong></td>
                <td colspan="2">
                  {patient.network}
                </td>
              </tr>              
              <tr>
                <td><strong>Deductible</strong></td>
                <td colspan="2">
                  {patient.deductible}
                </td>
              </tr>  
              <tr>
                <td><strong>Deductible Remaining</strong></td>
                <td colspan="2">
                  {patient.deductible_remaining}
                </td>
              </tr>  
              <tr>
                <td><strong>Deductible Applies</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('deductible_applies', e.target.value)}}>
                    {deductible_applies}
                  </select>
                </td>
              </tr>              

              <tr>
                <td><strong>OON Benefits</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('oon_benefits', e.target.value)}}>
                    {oon_benefits}
                  </select>
                </td>
              </tr>              
              <tr>
                <td><strong>Submit Claim</strong></td>
                <td colspan="2">
                  <select onClick={e => {e.stopPropagation()}} onChange={e => {this.update('submit_claim', e.target.value)}}>
                    {submit_claims}
                  </select>
                </td>
              </tr>              

              <tr>
                <td><strong>OOP Max Remaining</strong></td>
                <td colspan="2">
                  {patient.oop_max}
                </td>
              </tr>              

              <tr>
                <td><strong>Amount Due</strong></td>
                <td colspan="2">
                  {patient.copay}
                </td>
              </tr>              
              <tr>
                <td><strong>Verification Notes</strong></td>
                <td colspan="2">
                  {patient.verification_notes}
                </td>
              </tr>   
              <tr>
                <td>
                  <strong>All Patient Data</strong>
                </td>
                <td colspan="3">

                 
                  {csv_link}

                </td>
              </tr>
  
            </tbody>
          </table>
          </div>
        </div>
      );
  }
  render() {
    if(this.state.loading === true) return <Loading />;
    if(!this.state.patient) return '';
    else {
    	//let patient = this.state.patient;
    	let that = this;
  		
      let patient = parsePatient(this.state.patient);  		

    	if(this.state.level === 'super_admin') return this.superAdmin(patient);
      if(this.state.level === 'advantum') return this.advantum(patient);
      if(this.state.level === 'admin') return this.admin(patient);
      if(this.state.level === 'therapist' || this.state.level === 'super_therapist') return this.therapist(patient);
      if(this.state.level === 'verification') return this.verification(patient);
    }
  }
}

export default PatientDetail;