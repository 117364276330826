import React from "react";
import { useMembership } from "./hooks/useMembership";
import landingImg from "../images/member-landing.jpg";
import Logo from "../images/mw-logo-blueline.png";

const Membership = () => {
  const { exist, submitted, onSubmit } = useMembership();
  const onClickJoin = () => window.scrollTo(0, 0);

  return (
    <div className="page no-padding">
      <div
        className="landing"
        style={{
          backgroundImage: `url(${landingImg})`,
        }}
      >
        <div className="header">
          <a href="https://maternalwell.com">
            <img className="logo" src={Logo} alt="MaternalWell" />
          </a>
        </div>
        <div className="landing-content">
          <h1 className="heading">
            Giving moms the care they need and the convenience they want.
          </h1>
          <div className="join-form">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  required
                />
                <input type="text" placeholder="Last Name" name="last_name" />
              </div>
              <div className="form-group">
                <input placeholder="Email" type="email" name="email" required />
              </div>
              <div className="form-group">
                <input
                  placeholder="Password"
                  type="password"
                  name="password"
                  required
                />
              </div>
              <div className="form-group">
                <label>
                  <input type="radio" name="type" value="PG" required />
                  Pregnant
                </label>
                <label>
                  <input type="radio" name="type" value="PP" required />
                  Postpartum
                </label>
              </div>
              <div className="form-group">
                <button className="join-cta">Join Now</button>
              </div>
              <div className="form-group">
                {submitted && (
                  <p className="label">Request sent successfully!</p>
                )}
              </div>
              {exist && (
                <div className="form-group">
                  <p className="label">
                    Your email already exists! You can access the portal{" "}
                    <a href="https://portal.maternalwell.com/login">here</a>
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <div className="section">
        <h4>
          Join the MaternalWell community with our <i>FREE</i> Intro to Wellness
          Membership. Whether you prefer to learn at your own pace or talk to
          someone, we have you covered.
          <br />
          <br /> You’ll get daily online content to guide you on relevant
          prenatal or postpartum topics and be able to schedule convenient and
          affordable telehealth sessions with maternal specialists across all
          our competencies including:
        </h4>
        <div className="service-container">
          <ul className="services">
            <li>Mental Health</li>
            <li>Breastfeeding/Lactation</li>
            <li>Infant Sleep Coaching</li>
            <li>Nutrition </li>
          </ul>
          <ul className="services">
            <li>Physical Therapy</li>
            <li>Couples Counseling</li>
            <li>Paternal Wellness</li>
            <li>Lifestyle </li>
          </ul>
        </div>
      </div>
      <div className="full-width purple">
        <div className="section left">
          <h3 className="white">
            The Intro to Wellness Membership provides access to:{" "}
          </h3>
          <ul className="white">
            <li>
              <p className="paragraph white">
                <strong>The MaternalWell Program.</strong> Bite-size engaging
                daily content that is easy to digest and offers education,
                support, and motivation.
              </p>
            </li>
            <li>
              <p className="paragraph white">
                <strong>MaternalWell Telehealth.</strong> On demand scheduling
                for private telehealth sessions with our vetted licensed
                pregnancy/postpartum specialists.
              </p>
            </li>
            <li>
              <p className="paragraph white">
                <strong>MaternalWell Community.</strong> Private community of
                new and expecting moms to share experiences, listen and learn,
                or just vent!
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className="section">
        <h3>Get Started on Your Maternal Wellness Journey for Free! </h3>
        <div className="section-join">
          <button className="join-cta" onClick={onClickJoin}>
            Join Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Membership;
