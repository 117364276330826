import React, { useState } from 'react';
import { site } from './config/config'
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import spinner from './images/spinner.gif'

const CheckoutForm = ({amount, confirm, paying, setError, stripe_error, startSubmit, error, promo, promo_price, promo_success, applied_promo_code, _package, updatePackage, package_ref, user}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [cardComplete, setCardComplete] = useState(false);

  const createSubscription = async ({ paymentMethodId }) => {
    return (
      fetch(window.server_url + '/subscription', {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          user: user,
          patient_id: localStorage.getItem('patient_id'),
          paymentMethodId: paymentMethodId
        }),
      })
        .then((response) => {
          return response.json();
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            setError(result.error.message);
            error();
          }
          else return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            paymentMethodId: paymentMethodId,
            subscription: result,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        // .then(handlePaymentThatRequiresCustomerAction)
        // // If attaching this card to a Customer object succeeds,
        // // but attempts to charge the customer fail, you
        // // get a requires_payment_method error.
        // .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(function(result) {

            // The payment has been processed!
          if (result.subscription && result.subscription) {
            localStorage.setItem('emr-price', _package);
            confirm();
          }
          
          error();
        })
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          setError(error.message);
          error();
        })
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    startSubmit();

    if(_package === 'membership') { 
      stripe
      .createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
        billing_details: {
          name: user.first_name + ' ' + user.last_name,
        },
      })
      .then((result) => {
        if (result.error) {
          setError(result.error.message);
          error();
        } else {
          createSubscription({
            paymentMethodId: result.paymentMethod.id
          });
        }
      });
    } else {
      fetch(window.server_url + '/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          code: applied_promo_code,
          package: _package,
          user: user,
          _id: localStorage.getItem('patient_id')
        })
      }).then(function(response) {
      return response.json();
    }).then(function(responseJson) {
      if(responseJson.free) {
        localStorage.setItem('emr-price', _package);
        confirm();

      } else {
        var clientSecret = responseJson.client_secret;
        // Call stripe.confirmCardPayment() with the client secret.
        stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            type: 'card',
            card: elements.getElement(CardElement)
          }
        }).then(function(result) {
            if (result.error) {
              // Show error to your customer (e.g., insufficient funds)
              console.log(result.error.message);
              setError(result.error.message);
            } else {
              // The payment has been processed!
              if (result.paymentIntent.status === 'succeeded') {
                localStorage.setItem('emr-price', _package);
                confirm();
              }
            }
            error();
          });
      }
    });
    }
  };

  const onCardChange = (e) => {
    setCardComplete(!e.error && e.complete);
  };

  return (
    <div >
      <div class="package">
        <select onChange={updatePackage} ref={package_ref} name="package">
          <option selected={_package === '1-on-1'} value="1-on-1">1-on-1 Session - $100</option>
          <option selected={_package === 'membership'} value="membership">14-day Free Trial ($35/month after)</option>
          <option selected={_package === 'mommy-wellness-package'} value="mommy-wellness-package">Mommy Wellness Package - $300</option>
        </select>
      </div>
      <div class="promo">
        <input ref={promo} name="promo_code" placeholder="Promo Code" />
        <a onClick={updatePackage} class="btn update-promo">Apply</a>
      </div>
      {amount === 0 && _package != 'membership' ? '' : <CardElement onChange={onCardChange} />}
      {promo_success === true ? <div class="promo-success">Promo code applied successfully.</div> : false}
      {promo_success === false ? <div class="promo-error">No active promo codes found matching {promo.current.value}.</div> : false}
      {stripe_error ? <div class="promo-error">{stripe_error}</div> : false}
      <br/>
      <button class={`btn ${!stripe || !cardComplete || paying ? 'disabled' : ''}`} onClick={handleSubmit} disabled={!stripe || !cardComplete || paying}>
        {paying && <img className="loading-image" src={spinner} alt="loading" />}
        Pay ${(parseInt(amount) / 100).toFixed(2)}
      </button>
    </div>
  );
};
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);


class Payment extends React.Component {  
  constructor(props){
    super(props);
    let selected_package = '1-on-1';
    let price = localStorage.getItem('emr-price');

    if(price && ['1-on-1', 'membership', 'mommy-wellness-package'].indexOf(price) > -1) {
      selected_package = price;
    }
    this.state = {
      paying: false,
      promo_price: null,
      applied_promo_code: null,
      promo_success: null,
      package: selected_package
    }
    this.promo = React.createRef();
    this.package = React.createRef();
    this.updatePackage = this.updatePackage.bind(this);
    this.setError = this.setError.bind(this);
  }
  setError(error) {
    this.setState({stripe_error: error, promo_success: null})
  }
  componentDidMount() {
    this.updatePackage();
  }
  updatePackage() {
    this.setState({stripe_error: null})
    let that = this;
    fetch(window.server_url + '/apply_promo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          code: that.promo.current.value,
          package: that.package.current.value
        })
    }).then(function(response) {
      return response.json();
    }).then(function(responseJson) {
      console.log(responseJson)
      if(typeof responseJson.amount !== 'undefined') {
        if(responseJson.promo_success === true) {
          that.setState({promo_price: responseJson.amount, promo_success: true, applied_promo_code: responseJson.code, package: responseJson.package})
        } else {
          that.setState({promo_price: responseJson.amount, package: responseJson.package})

        }
      } else {
        that.setState({promo_success: false, promo_price: null, applied_promo_code: null})
      }
    });
  }
  render() {
    let that = this;

    return (
      <div className="page form">
        <h1 id="title">Payment</h1>
        <p>Pay securely with a credit card and get started with your maternal wellness.</p>
        <Elements stripe={stripePromise}>
          <CheckoutForm 
            promo={this.promo}
            user={this.props.user}
            package_ref={this.package}
            _package={this.state.package}
            promo_price={this.state.promo_price}
            promo_success={this.state.promo_success}
            applied_promo_code={this.state.applied_promo_code}
            updatePackage={this.updatePackage}
            paying={this.state.paying} 
            amount={this.state.promo_price !== null ? this.state.promo_price : this.default_price} 
            confirm={this.props.confirm} 
            startSubmit={e => {this.setState({paying: true})}}
            setError={this.setError}
            stripe_error={this.state.stripe_error}
            error={e => {this.setState({paying: false})}} />
        </Elements>

        {site.allow_restart ? [<br />, <a className="btn margin-top" onClick={e=>{localStorage.clear(); window.location.reload()}}>Start Over</a>] : null}
      </div>
    )
  }
}

export default Payment;