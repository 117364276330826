import React from 'react';

class Login extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      error: null
    }
    this.login = this.login.bind(this)
  }
  
  login(form) {
    let that = this;
    let form_data = new FormData(form);
    let email = form_data.get('email');
    let password = form_data.get('password');

    fetch(window.server_url + '/users/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        body: JSON.stringify({
          user: {
            email,
            password
          }
        })
      })
      .then(function (response) {
          if (!response.ok) {
              that.setState({error: true});
              throw Error(response.statusText);
          }

          return response.json();
      })
      .then(function (res) {
        console.log(res);
        if(!res.user) return false;
        else if (res.user.token) {
          localStorage.setItem('token',res.user.token);
          window.location.href = '/admin';
        }
        
      })
      .catch(error => console.log(error) );  
      that.setState({error: null});
  }
  
  render() {
    let error = '';
    if(this.state.error) {
      error = <span className="error">Email and password did not match, please try again.</span>;
    }
    return (
      <div className="modal login-form">
      	<h1>Log in</h1>
        {error}
        <form onSubmit={e => {e.preventDefault(); this.login(e.target)}}>
          <input name="email" type="email" placeholder="Email Address" /><br/>
          <input name="password" type="password" placeholder="Password" /><br/>
          <input type="submit" class="btn" value="Login" />
        </form>
      </div>
    );
  }
}

export default Login;