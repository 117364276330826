import React from "react";
import {
  useTable,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import ReactPaginate from "react-paginate";
import { useAssessments } from "./components/assessments/Assessments.hooks";

const Assessments = () => {
  const { columns, assessments, loading, keyword, updateFilter } =
    useAssessments();
  const {
    headerGroups,
    prepareRow,
    getTableProps,
    getTableBodyProps,
    page, // Instead of using 'rows', we'll use page,
    pageCount,
    gotoPage,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: assessments,
      initialState: { pageIndex: 0, pageSize: 25 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
  );

  const onPageChange = (event) => {
    console.log(event.selected);
    gotoPage(event.selected);
  };

  const onSearch = () => {
    gotoPage(0);
    setGlobalFilter(keyword);
  }

  return (
    <div className="page admin form">
      <h1>Assessments</h1>
      <div className="results-area">
        <div className="results-filter">
          <input
            class="search-field"
            name="search"
            placeholder="Filter assessments"
            defaultValue={keyword}
            onChange={(e) => {
              updateFilter(e.target.value);
            }}
          />
          <input
            type="button"
            class="btn"
            value="Search"
            onClick={onSearch}
          />
        </div>
        <table id="results" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="header">
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading && (
              <tr className="py-5">
                <td colSpan={headerGroups[0].headers.length}>Loading ...</td>
              </tr>
            )}
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} className="item">
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ReactPaginate
          className="pagination"
          pageCount={pageCount}
          onPageChange={onPageChange}
        />
      </div>
    </div>
  );
};

export default Assessments;
