import React from "react";
import Slider from "react-slick";
import { useMembership } from "./hooks/useMembership";
import landingImg from "../images/free-membership-bg.png";
import Logo from "../images/mw-logo-blueline.png";
import PeerIcon from "../images/peer_icon.png";
import PhoneIcon from "../images/phone_icon.png";
import TeleIcon from "../images/tele_icon.png";
import Spinner from "../images/spinner.gif";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const membershipFeaturs = [
  {
    title: "The MaternalWell Program",
    options: [
      "Bite-size engaging daily content",
      "Self-guided care",
      "Education, support, and motivation",
    ],
    image: PhoneIcon,
  },
  {
    title: "MaternalWell Telehealth",
    options: [
      "Private telehealth sessions",
      "On demand scheduling",
      "Vetted licensed maternal specialists",
    ],
    image: TeleIcon,
  },
  {
    title: "MaternalWell Community",
    options: [
      "Private community of new & expecting moms",
      "The no judgement zone",
      "Share, listen, learn, or just vent!",
    ],
    image: PeerIcon,
  },
];

const reviews = [
  {
    name: "Isabel D.",
    text: "This membership program is so educational and easy to digest. Just what I needed during this challenging time. It has been such an inspiration.",
  },
  {
    name: "Nicole W.",
    text: "The fact that mothers who have overcome their struggles take time out of their busy schedule to ‘pay it forward’ by encouraging women on peer sessions is a testament to how valuable they are.",
  },
  {
    name: "Elle J.",
    text: "My MaternalWell care coordinator is super helpful, and her check-ins make me feel like I always have someone to turn to.",
  },
  {
    name: "Michelle P.",
    text: "I joined the peer group sessions, and they were great! Very refreshing to hear from other moms and their stories. They helped me realize that my struggles were common for many new moms.",
  },
  {
    name: "Pratisa V.",
    text: "My MaternalWell lactation consultant has been nothing but informative, empathic, and encouraging during my virtual visits with her. She was thorough in evaluating my situation and coming up with a plan on how to help me and my baby be comfortable during feedings.",
  },
  {
    name: "Jane B.",
    text: "The peer group session was so comforting because I was able to share my struggles with other women who have had similar experiences. It felt nice to finally get to express my feelings without any judgement and learn new ways to better cope during the tough times.",
  },
  {
    name: "Vanessa E.",
    text: "I absolutely love this membership program. The daily reads are quick and easy to navigate. And they provide so much insight. Thank you, MaternalWell.",
  },
];

const FreeMembership = () => {
  const { exist, error, loading, onSubmit } = useMembership();
  const onClickJoin = () => window.scrollTo(0, 0);

  const settings = {
    className: "slider",
    arrows: false,
    dots: true,
    speed: 500,
    autoplaySpeed: 6000,
    autoplay: true,
    infinite: true,
    // centerMode: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const renderJoinForm = () => (
    <div className="join-form">
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label className="form-label" for="first_name">
            First Name
          </label>
          <input
            disabled={loading}
            type="text"
            placeholder="First Name"
            name="first_name"
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" for="last_name">
            Last Name
          </label>
          <input
            disabled={loading}
            type="text"
            placeholder="Last Name"
            name="last_name"
          />
        </div>
        <div className="form-group">
          <label className="form-label" for="email">
            Email
          </label>
          <input
            disabled={loading}
            placeholder="Email"
            type="email"
            name="email"
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label" for="password">
            Password
          </label>
          <input
            disabled={loading}
            placeholder="Password"
            type="password"
            name="password"
            required
          />
        </div>
        <div className="form-group">
          <label>
            <input
              disabled={loading}
              type="radio"
              name="type"
              value="PG"
              required
            />
            Pregnant
          </label>
          <label>
            <input
              disabled={loading}
              type="radio"
              name="type"
              value="PP"
              required
            />
            Postpartum
          </label>
        </div>
        <div className="form-group">
          {loading ? (
            <img className="join-spinner" src={Spinner} alt="spinner" />
          ) : (
            <button disabled={loading} className="join-cta">
              Join Now
            </button>
          )}
        </div>
        {error && (
          <div className="form-group">
            <p className="label">
              There was an error while making request.
            </p>
          </div>
        )}
        {exist && (
          <div className="form-group">
            <p className="label">
              Your email already exists! You can access the portal{" "}
              <a href="https://portal.maternalwell.com/login">here</a>
            </p>
          </div>
        )}
      </form>
    </div>
  );

  return (
    <div className="page no-padding">
      <div className="header center">
        <a href="https://maternalwell.com">
          <img className="logo" src={Logo} alt="MaternalWell" />
        </a>
      </div>
      <div
        className="free-membership-landing"
        style={{
          backgroundImage: `url(${landingImg})`,
        }}
      >
        <div className="landing-header">
          <h1 className="heading">
            Get the care you need
            <br />
            with the convenience you want
          </h1>
          <p>
            Join the MaternalWell community with our <i>FREE</i> Intro to
            Wellness Membership.
          </p>
          {renderJoinForm()}
          <p>
            Whether you prefer to go through our program at your own pace or to
            talk to someone, we have you covered.
          </p>
          <div className="service-container">
            <ul className="services">
              <li>Mental Health</li>
              <li>Breastfeeding/Lactation</li>
              <li>Infant Sleep Training</li>
              <li>Nutrition</li>
            </ul>
            <ul className="services">
              <li>Physical Therapy</li>
              <li>Couples Counseling</li>
              <li>Lifestyle Coaching</li>
              <li>Doulas</li>
            </ul>
          </div>
        </div>
        <div className="landing-content">
          {renderJoinForm()}
          <div className="review-section">
            <h4>Here's what our members have to say</h4>
            <Slider {...settings}>
              {reviews.map((review, index) => (
                <div className="review-panel" key={index}>
                  <p className="review-content">{review.text}</p>
                  <strong className="review-name">{review.name}</strong>
                  {review.title && (
                    <span className="review-title">{review.title}</span>
                  )}
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <div className="section">
        <div className="features">
          {membershipFeaturs.map((feature, index) => (
            <div className="feature" key={index}>
              <img
                className="feature-image"
                src={feature.image}
                alt={feature.title}
              />
              <div className="feature-content">
                <h3 className="feature-title">{feature.title}</h3>
                <ul className="feature-text">
                  {feature.options.map((option, index) => (
                    <li key={index}>{option}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="purple-section">
        <div className="section feature-video">
          <video width="100%" height="400" poster={Logo} controls>
            <source
              src="https://mw-dev-asset.s3.amazonaws.com/MW-VIDEO_F2.mp4"
              type="video/mp4"
            />
          </video>
          <div className="feature-content">
            <h3>Get Started on Your Maternal Wellness Journey for Free!</h3>
            <button className="join-cta" onClick={onClickJoin}>
              Join Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FreeMembership;
