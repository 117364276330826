import React from 'react';
import moment from 'moment-timezone';
import parsePatient from './parsePatient';
import { CSVLink, CSVDownload } from "react-csv";
import Login from './Login';
import Loading from './Loading';
import Cleave from 'cleave.js/react';

// import ReactExport from "react-data-export";

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class DownloadReport extends React.Component {
  constructor(props){
    super(props);
    this.state = {
    	report: null,
      loading: true,
      downloadFile: null,
      fileError: null,
      level: null
    }
    this.loginToken = this.loginToken.bind(this);
    // this.downloadData = this.downloadData.bind(this);
  }
  componentDidMount() {
  	if(!this.state.patient) this.loginToken();
  }
  loginToken() {
    let token = localStorage.getItem('token'),
    		that = this;

    fetch(window.server_url + '/admin/patients/download_report/' + this.props.match.params.patient_id, {
        method: 'GET',
        headers: {
          //'Content-Type': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'Authorization': 'Token '+token,
          'Accept': 'any',
        },
      })
      .then(function (response) {
         response.headers.forEach(function(val, key) { console.log(key + ' -> ' + val); });

          if (!response.ok) {
             	//localStorage.removeItem('token');
		        	//window.location.href = '/admin';
              that.setState({fileError:response.statusText });
              throw Error(response.statusText);
		        	return false;

          }
          return response.json();
      })
      .then(function (res) {
        console.log(res);
        
        if(!res.filename) {
        	//localStorage.removeItem('token');
        	window.location.href = '/admin/patients/'+this.props.match.params.patient_id;
        	return false;
        } else {
          that.setState({downloadFile: res.filename, loading: false});
          window.setTimeout(function() {document.getElementById('download-file').click();})
        }
        // } else if (res.user.token) {
        //   localStorage.setItem('token',res.user.token);
        // }
        
      })
      .catch(error => console.log(error) );  
  }
  
 
  render() {
    if(this.state.loading === true) return <Loading />;
    else if(this.state.downloadFile) {
    	return (
        <div className="modal">
          <h3>The report has been generated:</h3>
          <a className="btn" id="download-file" href={this.state.downloadFile}>Download Report</a>
        </div>
      )
    }
    else {
      return (
        <div className="modal">
          <p>There was an error generating the file: {this.state.fileError}</p>
        </div>
      )
    }
  }
}

export default DownloadReport;