// import { site } from '../config/config'

const titleMapping = {
  'Are you breastfeeding your baby? Or do you plan on or would you like to breastfeed your baby?': 'Are you currently breastfeeding or plan to breastfeed?',
  'How aware are you of how nutrition, mental and physical health can impact milk supply and breastfeeding?': 'How aware are you of the impact that nutrition, mental and physical health have on milk supply and breastfeeding?',
  'How would you describe how you manage your schedule?': 'How would you describe your daily schedule?',
  'How would you describe your nutritional habits at this point of time?': 'How would you describe your nutritional habits at this point in time?',
  'How aware are you regarding how nutrition can impact your pregnancy, delivery and postpartum health?': 'How aware are you of the impact that nutrition can have on your pregnancy, delivery, and postpartum health?'
}

const parseAssessment = (_assessment) => {
  let assessment = Object.assign({}, _assessment);

  assessment.data_horizontal = {};
  if (
    assessment.assessment &&
    typeof assessment.assessment.forEach === "function"
  ) {
    assessment.data_horizontal["email"] = assessment.email;
    assessment.data_horizontal["created date"] = new Date(assessment.createdAt).toDateString();
    assessment.assessment.forEach((question) => {
      if (question.response) {
        const title = question.title.split(' ').map(word => word.trim()).filter(word => word).join(' ');
        const newTitle = titleMapping[title] ? titleMapping[title] : title;
        assessment.data_horizontal[newTitle] = question.response
          .toString()
          .replace(/\n/g, "\\n")
          .replace(/"/g, "”");
      }
    });
  }

  return assessment;
};
export default parseAssessment;
