import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import { getAssessment, getAssessments } from "../../../service";

const useAssessments = () => {
  const [assessments, setAssessments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');

  const columns = useMemo(
    () => [
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Date",
        accessor: "updatedAt",
        Cell: ({ row: { original } }) => (
          <div className="font-semibold text-sm">
            {moment(original.updatedAt).format()}
          </div>
        ),
      },
      {
        Header: "View",
        Cell: ({ row: { original } }) => (
          <Link to={`/admin/assessments/${original._id}`}>View Q/A</Link>
        ),
      },
    ],
    []
  );

  const getData = async () => {
    setLoading(true);
    try {
      const res = await getAssessments();
      if (res.data.data) {
        const data = res.data.data;
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        console.log(data);
        setAssessments(data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      throw error;
    }
  };

  const updateFilter = (value) => setKeyword(value);

  useEffect(() => {
    getData();
  }, []);

  return {
    loading,
    keyword,
    columns,
    assessments,
    updateFilter,
  };
};

const useAssessmentDetail = () => {
  const { id } = useParams();
  const [data, setData] = useState({});

  const getData = async (id) => {
    try {
      const res = await getAssessment(id);
      if (res.data.data) {
        setData(res.data.data);
      }
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    if (id) {
      getData(id);
    }
  }, [id]);

  return {
    data,
  };
};

export { useAssessments, useAssessmentDetail };
