import React from 'react';
import Search from './search';
import Form from './form';
import Payment from './payment';
import moment from 'moment';
import { site } from './config/config';

class App extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      intake_done: false,
      initial_form: null,
      post_intake_form: null,
      user: null
    }
    this.done = this.done.bind(this);
    this.payment = this.payment.bind(this);
    this.confirm = this.confirm.bind(this);
    this.postIntakeForm = this.postIntakeForm.bind(this);
  }
  componentDidMount() {
    let params = window.location.search;
    if (params) {
      console.log('params', params)
      params = new URLSearchParams(params);
      let price = params.get('price');
      console.log('params', params)
      if (price) localStorage.setItem('emr-price', price);
      window.location.href = '/';
    }

    if(localStorage.getItem('emr-paid')) this.confirm();
    if(localStorage.getItem('emr-payment')) this.payment();
    if(localStorage.getItem('emr-post-intake')) this.postIntakeForm();
    var that = this;
    if(localStorage.getItem('patient_id')) {
      fetch(window.server_url + '/patients/' + localStorage.getItem('patient_id'), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        }
      })
      .then(function (response) {
          if (!response.ok) {
            let price = localStorage.getItem('emr-price');
            console.log('price!', price);
            localStorage.clear();
            if(price) localStorage.setItem('emr-price', price);

            that.setState({initial_form: 'regform-first'});
            //throw Error(response.statusText);
          }
          return response.json();
      })
      .then(function (res) {
        if(!res.error && res.data.next_form) {
          if(res.data.next_form === 'done') {
            let user = {};
            let email = res.data.form['regform-first'].find((e) => {return e.name === 'Email Address'});
            let phone_type = res.data.form['regform-first'].find((e) => {return e.name === 'Best Number to Reach You'})
            let phone_field = res.data.form['regform-first'].find((e) => {return e.name === 'Phone Number'});
            if(!phone_field) phone_field = res.data.form['regform-first'].find((e) => {return e.name === 'Mobile Phone Number'})

            user.state = res.data.form['regform-first'].find((e) => {return e.name === 'State'}).value;
            user.first_name = res.data.form['regform-first'].find((e) => {return e.name === 'First Name'}).value;
            user.last_name = res.data.form['regform-first'].find((e) => {return e.name === 'Last Name'}).value;
            user.phone = phone_field.value;
            user.phone_type = phone_type ? phone_type.value : '';
            user.address_1 = res.data.form['regform-first'].find((e) => {return e.name === 'Street Address'}).value;
            user.city = res.data.form['regform-first'].find((e) => {return e.name === 'City'}).value;
            user.state = res.data.form['regform-first'].find((e) => {return e.name === 'State'}).value;
            user.zip = res.data.form['regform-first'].find((e) => {return e.name === 'Zip Code'}).value;
            user.email = email ? email.value : '';
            user.insurance = res.data.form['regform-insurance'] ? res.data.form['regform-insurance'].find((e) => {return e.name === 'Insurance Name'}).value : null;
            user.dob = res.data.form['regform-insurance'] ? res.data.form['regform-insurance'].find((e) => {return e.name === 'Date of Birth'}).value : null;
            return that.done(user);
          } else {
            that.setState({ initial_form: res.data.next_form });
          }
        } else {
          console.log('clear');
          let price = localStorage.getItem('emr-price');
          localStorage.clear();
          if(price) localStorage.setItem('emr-price', price);

          that.setState({initial_form: 'regform-first'});
        }
      })
      .catch(error => console.log(error) );   
    } else {
      let price = localStorage.getItem('emr-price');

      localStorage.clear();
      if(price) localStorage.setItem('emr-price', price);

      this.setState({initial_form: 'regform-first'});
    }
  }
  confirm() {
    if(localStorage.getItem('emr-price') === 'membership') {
      localStorage.clear();
      window.location.href=`${site.domain}/membership-confirmation/`;
      return;
    }
    this.setState({payment: false, paid: true})
    localStorage.setItem('emr-paid', true);
  }
  postIntakeForm() {
    this.setState({payment: false, post_intake_form: true, initial_form: 'regform-post-appointment'})
    localStorage.setItem('emr-post-intake', true);
  }
  payment() {
    if(localStorage.getItem('emr-price') === 'free') {
      this.setState({payment: false, paid: true})
      localStorage.setItem('emr-paid', true);
    } else {
      this.setState({payment: true})
      localStorage.setItem('emr-payment', true);
    }
  }
  done(user) {
    let that = this;
    
    console.log('testtest');
    fetch(window.server_url + '/patients/' + localStorage.getItem('patient_id'), {
      method: 'PUT',
      body: JSON.stringify({
        completed_intake: moment().format('YYYY-MM-DD')
      }),
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      }
    })
    .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
    })
    .then(function (res) {
      if(localStorage.getItem('emr-price') === 'membership') {
        that.setState({
          intake_done: true,
          user: user,
          payment: true
        })
      } else if (localStorage.getItem('emr-price') === 'free') {
        localStorage.clear();
        window.location.href=`${site.domain}/free-membership-confirmation/`;
        return;
      } else {
        that.setState({
          intake_done: true,
          user: user
        })
      }
    });
    // #TODO: post back to server here that user is done
    
  }
  render() {
    if(this.state.payment && !this.state.paid)
      return <Payment confirm={this.confirm} user={this.state.user} />;
    if(this.state.post_intake_form || (!this.state.intake_done && this.state.initial_form))
      return <Form form={this.state.initial_form} done={this.done} />;
    if(this.state.paid || (this.state.intake_done && this.state.user))
      return <Search postIntakeForm={this.postIntakeForm} paid={this.state.paid} payment={this.payment} user={this.state.user} />
    return <div className="modal">Loading...</div>;
  }
}

export default App;