import React from "react";
import { useMembership } from "./hooks/useMembership";
import overlapImg from "../images/member-overlap.png";
import Logo from "../images/logo@2x.png";

const MembershipContent = () => {
  const { exist, submitted, onSubmit } = useMembership();
  const onClickJoin = () => window.scrollTo(0, 0);

  return (
    <div className="page green no-padding">
      <div className="content-landing">
        <div className="header">
          <a href="https://maternalwell.com">
            <img className="logo" src={Logo} alt="MaternalWell" />
          </a>
        </div>
        <div className="landing-header">
          <h1 className="heading">
            Get the care you need with the convenience you want.
          </h1>
          <h2>
            Join the Maternalwell Community with our FREE Maternal Wellness
            Membership.
          </h2>
        </div>
        <div className="landing-content">
          <div className="join-content">
            <ul>
              <li>Empower Yourself With Our FREE Self-Guided Care Program.</li>
              <li>
                Bite-Sized Daily Doses of Trusted Information and Motivation.
              </li>
              <li>Engaging and Interactive Program at Your Own Pace.</li>
            </ul>
          </div>
          <div className="join-form">
            <form onSubmit={onSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  required
                />
              </div>
              <div className="form-group">
                <input type="text" placeholder="Last Name" name="last_name" />
              </div>
              <div className="form-group">
                <input placeholder="Email" type="email" name="email" required />
              </div>
              <div className="form-group">
                <input
                  placeholder="Password"
                  type="password"
                  name="password"
                  required
                />
              </div>
              <div className="form-group">
                <p className="label">
                  Are you currently pregnant or postpartum?
                </p>
              </div>
              <div className="form-group">
                <label>
                  <input type="radio" name="type" value="PG" required />
                  Pregnant
                </label>
                <label>
                  <input type="radio" name="type" value="PP" required />
                  Postpartum
                </label>
              </div>
              <div className="form-group">
                <button className="join-cta">Join Now For Free</button>
              </div>
              <div className="form-group">
                {submitted && (
                  <p className="label">Request sent successfully!</p>
                )}
              </div>
              {exist && (
                <div className="form-group">
                  <p className="label">
                    Your email already exists! You can access the portal{" "}
                    <a href="https://portal.maternalwell.com/login">here</a>
                  </p>
                </div>
              )}
            </form>
          </div>
        </div>
        <img className="overlap-img" src={overlapImg} alt="overlap" />
      </div>
    </div>
  );
};

export default MembershipContent;
