import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useAssessmentDetail } from "./components/assessments/Assessments.hooks";

const AssessmentDetail = () => {
  const { data } = useAssessmentDetail();

  return (
    <div className="page admin form">
      <h1>Assessment Question and Answers</h1>
      <Link className="btn left" to="/admin/assessments">
        Back
      </Link>
      <div className="results-area">
        <label>Email: <strong>{data.email}</strong></label>
        <label>Entry Date: <strong>{moment(data.updatedAt).format()}</strong></label>
        <h3>Question and Answers</h3>
        {data.assessment &&
          data.assessment
            .filter((entry) => entry.title)
            .map((entry) => (
              <label>
                <strong>{entry.title}</strong>: {entry.response}
              </label>
            ))}
      </div>
    </div>
  );
};

export default AssessmentDetail;
