import React from 'react';
import ReactDOM from 'react-dom';
import './style.scss';
import { site } from './config/config';
import App from './App';
import Membership from './pages/Membership';
import MembershipContent from './pages/MembershipContent';
import FreeMembership from './pages/FreeMembership';
import Admin from './admin/Admin';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-KX7BCLC'
}
 
TagManager.initialize(tagManagerArgs)

const AppRoot = () => (
  <Router>
    <Switch>
      <Route path="/" exact component={App} />
      <Route path="/start-free-membership" exact component={Membership} />
      <Route path="/free-membership-content" exact component={MembershipContent} />
      <Route path="/join-free-membership" exact component={FreeMembership} />
      <Route path="/admin" component={Admin} />
    </Switch>
  </Router>
);

window.preventLeave = function (e) {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = '';
};

window.base_title = site.title;
window.server_url = process.env.REACT_APP_SERVER_URL || '/api';

ReactDOM.render(<AppRoot />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
